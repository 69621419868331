import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import niji from "../../img/2025niji.jpg";
import taisaku from "../../img/taisaku.jpeg";
import { device } from '../MainContents/Style/StyleDevice';
const DivTop = styled.div`
  margin-top: 170px;
  @media ${device.mobile} {
    margin-top: 80px;
  }
`;
const HeadingTitle = styled.div`
  text-align: left;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.5em;
  color: #9b003f;
  margin-bottom: 30px;
  @media ${device.mobile} {
    font-size: 26px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-top: 18px;
  }
`;

const CourseKokuritu = styled.h1`
    border-color: #9b003f;
    width: 500px;
    margin-top: 50px;
    margin-bottom: 50px;
    @media ${device.mobile} {
        width: 370px;
    }
`



const NijiTaisaku = () => {

  return (
    <>
      <DivTop>
        <div className="relative">
          <img src={taisaku} alt="Top Image" className="w-full opacity-40" />
          <HeadingTitle className="mb-5 absolute tracking-widest p-4" style={{ left: '10%', top: '15%' }}>
            二次対策直前講座<br />
          </HeadingTitle>
        </div>
        <div>
          <section className="text-gray-600 body-font">
            <div className="container mx-auto max-w-screen-md flex px-5 py-24 md:flex-row flex-col items-center">
              <div className="lg:flex-grow w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">

                <CourseKokuritu className="mt-5 pl-2 sm:text-4xl text-3xl font-bold pb-2 border-b-4 border-red-600">
                  期間・受講料・事務手数料
                </CourseKokuritu>

                <h6 className="mt-5 pl-2 sm:text-xl text-lg font-bold pb-2 border-l-4 border-red-300" style={{ borderColor: '#D491AC', width: '150px' }}>
                  期間
                </h6>
                <p className="mb-4 text-xl leading-relaxed text-black">
                  2025/1/27～2/22
                </p>

                <h6 className="mt-5 pl-2 sm:text-xl text-lg font-bold pb-2 border-l-4 border-red-300" style={{ borderColor: '#D491AC', width: '150px' }}>
                  受講料
                </h6>
                <p className="mb-4 text-xl leading-relaxed text-black">
                  小論文以外：無料<br />
                  小論文：10,000円
                </p>

                <h6 className="mt-5 pl-2 sm:text-xl text-lg font-bold pb-2 border-l-4 border-red-300" style={{ borderColor: '#D491AC', width: '150px' }}>
                  事務手数料
                </h6>
                <p className="mb-4 text-xl leading-relaxed text-black">
                  外部一般生：5,000円<br />
                  ※土佐塾予備校生は、事務手数料・小論文受講料ともに不要です
                </p>

                <CourseKokuritu className="mt-5 pl-2 sm:text-4xl text-3xl font-bold pb-2 border-b-4 border-red-600">
                  申込
                </CourseKokuritu>
                <p className="mb-4 text-xl leading-relaxed text-black">
                  事前に申し込みが必要です<br />
                  <a class="text-red-600" href='https://tosayobi.ac.jp/pdf/2025application.pdf' target='_blank'>二次対策直前講座　申込書</a>(PDF)
                </p>

                <CourseKokuritu className="mt-5 pl-2 sm:text-4xl text-3xl font-bold pb-2 border-b-4 border-red-600">
                  時間割
                </CourseKokuritu>
                <p className="mb-4 text-xl leading-relaxed text-black">
                  <a class="text-red-600" href='https://tosayobi.ac.jp/pdf/2025niji.pdf' target='_blank'>二次対策直前講座　時間割</a>(PDF)
                </p>
                <img src={niji} alt="Top Image" className="w-full mx-auto" />
              </div>

            </div>
          </section>
        </div>
      </DivTop>
    </>
  );
};

export default NijiTaisaku;
